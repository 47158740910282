import { ValidationDetailsType } from '../common/types/fields';

export const isStatusDisabled = (status?: 'disabled' | 'complete' | 'incomplete') => !status || status === 'disabled';

export const isFieldDisabled = (obj?: { status?: 'disabled' | 'complete' | 'incomplete' }) => isStatusDisabled(obj?.status);

export const isFieldRequired = (obj?: { validation?: Array<ValidationDetailsType> }) => (obj?.validation ?? []).some((item) => item.type === 'required');

export default isStatusDisabled;

