import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';


interface ISwitchProps<TValue extends string> {
  id?: string
  label1: TValue,
  label2: TValue,
  onChange: (value: TValue) => void,
  className?: string,
  defaultVal?: TValue,
}

const Switch = <TValue extends string = string>({
  id,
  label1,
  label2,
  className,
  defaultVal,
  onChange,
}: ISwitchProps<TValue>) => {
  const [isToggled, setIsToggled] = useState(defaultVal === label1);

  const onToggle = useCallback(() => {
    onChange(!isToggled ? label1 : label2);
    setIsToggled(!isToggled);
  }, [label1, label2, isToggled, onChange]);

  return (
    <label
      className={classNames(styles.switch, className)}
      htmlFor={id || 'switch-control'}
    >
      <input
        className={styles.input}
        type="checkbox"
        onChange={onToggle}
        id={id || 'switch-control'}
      />
      <span
        className={classNames(styles.label, {
          [styles.active]: isToggled,
        })}
      >
        {label1}
      </span>
      <span
        className={classNames(styles.label, {
          [styles.active]: !isToggled,
        })}
      >
        {label2}
      </span>
    </label>
  );
};

export default Switch;
