import type { IFieldsUpdate, IWholeSchemaWithState } from 'containers/GenericFieldsHandlingLayer/types';

import { UnitedFieldState } from 'common/types/fields';
import { requestHandlerFabric } from './instance';
// eslint-disable-next-line import/named
import { apiEndpoints } from './routes';
// TODO: Get rig of project specific logic
// eslint-disable-next-line local-rules/validate-import
import { ARGProjectFields } from '../@arg,@demo,@greenline/containers/project-fields';

export const updateFields = async (sessionId: string, updatedFields: IFieldsUpdate) => {
  const result = (await requestHandlerFabric().post<{ state: Array<UnitedFieldState> }>(apiEndpoints().updateFieldsState(sessionId), updatedFields)).data;
  return result.state;
};

export const printPDF = async (sessionId: string, content: string) => {
  const result = (await requestHandlerFabric().post<{ url: string }>(apiEndpoints().printPDF(), {
    content,
    sessionId,
  })).data;
  return result.url;
};

export const getSchemaWithCache = async (sessionId?: string, params?: Record<string, string>) => {
  const result = (await requestHandlerFabric().get<IWholeSchemaWithState>(apiEndpoints().getWholeSchema(sessionId, params))).data;

  const hose = params ? params.hose : undefined;
  if (hose && !sessionId) {
    const { session } = result;
    const withHoseState = await updateFields(session.sessionId, {
      sessionId: session.sessionId,
      data: {
        [ARGProjectFields.preselectedHoseProduct]: hose, // TODO: Get rig of project specific logic, consider an interceptor or do this on BE
      },
    });
    result.state = withHoseState;
  }
  return result;
};
