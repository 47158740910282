import { DeepKeys } from 'utils/type-tools';

const customization = {
  /**
   * Exposed project specific variables like logos, email addresses, etc.
   */
  variables: {
    pdf: {
      logo: '/@arg-pdf-logo.jpg',
    },
    contactEmail: process.env.REACT_APP_CONTACT_EMAIL,
    contactEmailTitle: 'Custom Assembly Configuration',
  },
  /**
   * CSS variable overrides
   * @see /src/styles/css-variables.scss
   */
  css: {
    // Example 'color-accent': 'red',
  },
  texts: {
    step1: {
      title: 'Select Hose',
      tabName: 'Select Hose',
      description: 'Please use the below drop-down options to select the hose specifications you are looking for.',
    },
    step2: {
      title: 'Select Fittings',
      tabName: 'Select Fittings',
      description: 'Please use the below drop downs to select the end style and size you need.',
    },
    step3: {
      title: 'Overall Assembly Length',
      tabName: 'Assembly Length',
      description: 'Please enter the required finished assembly length in inches or feet. See below images for measurement guide.',
    },
    step4: {
      title: 'Angle of Rotation',
      tabName: 'Angle of Rotation',
      description: `Please enter the angle of rotation between fitting&nbsp;1 and fitting&nbsp;2 using the image below as 
a&nbsp;guide.<br/> Fitting 1 will be 0 degrees.`,
    },
    step5: {
      title: 'Accessories/Options',
      tabName: 'Accessories',
      description: 'Please select any optional accessories from the list of available.',
    },
    step6: {
      title: 'Confirm Your Assembly',
      tabName: 'Confirm Assembly',
      // eslint-disable-next-line max-len
      description: 'Please verify component selections, specifications, and assembly length for your application. Custom assemblies are non-returnable and non-refundable. To modify, click the item below.',
    },
    pdf: {
      title: 'Your Assembly',
      // eslint-disable-next-line max-len
      tos: 'Please verify component selections, specifications, and assembly length for your application. Custom assemblies are non-returnable and non-refundable.',
      hoseSelection: 'Hose Selection',
      fitting1: 'Fitting 1',
      fitting2: 'Fitting 2',
      overallAssemblyLength: 'Overall Assembly Length',
      angleOfRotation: 'Angle of Rotation',
      customerPartNumber: 'Customer Part Number',
      assemblyQty: 'Assembly Qty',
      testingTitle: 'Performed to industry standards',
      testingDescription: 'Pressure Test and Certify Assembly',
      accessories: 'Accessories',
    },
    nav: {
      profile: 'Profile',
      product: 'Product Type',
    },
    btn: {
      showFittingDetails: 'Show fitting details',
      showHoseDetails: 'Show hose details',
      hideFittingDetails: 'Hide fitting details',
      hideHoseDetails: 'Hide hose details',
    },
  },
} as const;

export type Type = typeof customization;
export type Texts = DeepKeys<typeof customization>;

export default customization;
