import React, { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import type { IViewProps } from 'common/types/views';
import { FieldsType } from 'common/types/fields';
import type { WithChildren } from 'common/types';
import { IFormValues } from 'common/components/FormHF/types';

import { useGenericFieldsActionsAdopted, useGenericFieldsDataAdopted } from 'containers/FormDataAdapter';
import { determineAndApplyErrorToForm } from 'utils/determineAndApplyErrorToForm';
import { useIsNextStepAvailable } from 'hooks/useIsNextStepAvailable';

import FormWrapper from 'common/components/FormWrapper';
import Rotation from 'common/components/Rotation';
import ViewsButtonsBlock from 'common/components/ViewsButtonsBlock';
import { FormLoader } from 'common/components/Loader';
import FieldGenerator from 'common/components/FieldGenerator';

import { GeneratorDropdownFieldType } from 'common/components/FieldGenerator/types';
import { OptionType } from 'common/components/Select/types';
import { isFieldDisabled } from 'utils/isStatusDisabled';
import { ARGProjectFields } from '@arg,@demo,@greenline/containers/project-fields';
import {
  usePrepareDefaultAngleOfRotationFormData,
  useAngleOfRotationFieldWithDefaultSelected,
} from './utils';
import { DEFAULT_ROTATION_ANGLE, DEFAULT_ROTATION_ANGLE_STEP } from './constants';

import styles from './styles.module.scss';


const AngleRotationView = ({
  onNextClickHandler,
  onBackClickHandler, onResetHandler,
  title,
  description,
  fieldsNames,
  children,
  isNextDisabled,
  clearProgressError,
  handleSetNoFieldsAvailable,
  isLoading: isCartLoading,
}: WithChildren<IViewProps<ARGProjectFields>>) => {
  const idForm = 'form-angle-rotation';
  const { error, isLoading, order } = useGenericFieldsDataAdopted();
  const { updateFieldsState, getFieldsByName } = useGenericFieldsActionsAdopted();

  const fields = useMemo(() => getFieldsByName(fieldsNames), [getFieldsByName, fieldsNames]);
  const rotationStep = useMemo(() => {
    const rotationOptions = (fields[ARGProjectFields.angleOfRotation] as GeneratorDropdownFieldType).value?.options;
    if (rotationOptions?.length) {
      return Number(rotationOptions[0].id) === 0
        ? Number(rotationOptions[1].id)
        : (Number(rotationOptions[0].id) + Number(rotationOptions[1].id)) / 2; // assume there are stable rotation step
    }
    return DEFAULT_ROTATION_ANGLE_STEP;
  }, [fields]);
  const aorField = useAngleOfRotationFieldWithDefaultSelected(fields, updateFieldsState);

  const defaultValues = usePrepareDefaultAngleOfRotationFormData(fields);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    formState: { errors },
    resetField,
    setError,
  } = useForm<IFormValues<ARGProjectFields>>({ defaultValues });

  const angleOfRotationWatched = watch(ARGProjectFields.angleOfRotation);
  const angleOfRotationFormValue = useMemo(() => {
    const changedFormVal = !!angleOfRotationWatched && Number((angleOfRotationWatched as OptionType).id);
    let changedServerValue = DEFAULT_ROTATION_ANGLE;
    if (aorField) {
      changedServerValue = aorField?.value?.selected?.length
        ? Number(aorField?.value?.selected![0].id)
        : DEFAULT_ROTATION_ANGLE;
    }
    return changedFormVal || changedServerValue;
  }, [angleOfRotationWatched, aorField]);

  // eslint-disable-next-line no-unused-vars
  const onSubmitSuccess: SubmitHandler<IFormValues<ARGProjectFields>> = useCallback((data) => {
    onNextClickHandler();
  }, [onNextClickHandler]);

  const handleFieldUpdate = useCallback((
    fieldType: FieldsType,
    fieldName: ARGProjectFields,
    changedValue?: IFormValues<ARGProjectFields>[keyof IFormValues<ARGProjectFields>],
  ) => {
    if (clearProgressError) { clearProgressError(); }
    updateFieldsState(fieldType, fieldName, changedValue);
  }, [updateFieldsState, clearProgressError]);

  const handleChangeAngle = useCallback((angle: number) => {
    if (aorField) {
      const element = aorField.value?.options?.find((el) => +el.id === angle);
      if (element) {
        setValue(ARGProjectFields.angleOfRotation, element);
        handleFieldUpdate(FieldsType.dropdown, ARGProjectFields.angleOfRotation, element);
      }
    } else {
      console.error('Angle of rotation field has unexpected field type and can be used properly');
    }
  }, [aorField, handleFieldUpdate, setValue]);

  useEffect(() => {
    if (error) {
      determineAndApplyErrorToForm(error, setError);
    }
  }, [error, setError]);

  useIsNextStepAvailable(order, fieldsNames, fields, handleSetNoFieldsAvailable);

  return (
    <FormWrapper
      header={title}
      description={description}
    >
      <form
        id={idForm}
        onSubmit={handleSubmit(onSubmitSuccess)}
      >
        {(isLoading || isCartLoading) && <FormLoader />}
        {order && order.map((fieldName) => {
          if (fieldsNames.includes(fieldName as ARGProjectFields) && !!fields[fieldName]) {
            if (fieldName === ARGProjectFields.angleOfRotation && aorField) {
              return (
                <div
                  className={styles.angleOfRotationGroup}
                  key={aorField.name}
                >
                  <FieldGenerator<IFormValues<ARGProjectFields>>
                    field={aorField}
                    name={aorField.name as ARGProjectFields}
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    getFieldState={getFieldState}
                    onChangeHandler={handleFieldUpdate}
                    isDisabled={isFieldDisabled(aorField)}
                    resetField={resetField}
                    className={styles.select}
                  />
                  <Rotation
                    rotationSnap={rotationStep}
                    rotationValue={angleOfRotationFormValue}
                    onChangeRotationValue={handleChangeAngle}
                  />
                </div>
              );
            }
            return (
              <FieldGenerator<IFormValues<ARGProjectFields>>
                field={fields[fieldName]!}
                name={fields[fieldName]!.name as ARGProjectFields}
                control={control}
                watch={watch}
                setValue={setValue}
                getFieldState={getFieldState}
                key={fields[fieldName]!.name}
                onChangeHandler={handleFieldUpdate}
                isDisabled={isFieldDisabled(fields[fieldName])}
                resetField={resetField}
              />
            );
          }
          return null;
        })}
        {children}
      </form>
      <ViewsButtonsBlock
        idForm={idForm}
        onResetClickHandler={onResetHandler}
        onBackClickHandler={onBackClickHandler}
        isNextDisabled={isNextDisabled}
      />
    </FormWrapper>
  );
};

// @ts-ignore
export default AngleRotationView;
