import React from 'react';

import { WithChildren } from 'common/types';
import { ICustomOptionProps } from 'common/components/FieldGenerator/types';

import { useLengthTypeData } from 'containers/AdditionalDataStorage/context';
import { useGenericFieldsActionsAdopted } from 'containers/FormDataAdapter';
import { getSingleNumberValue } from 'api/cart/utils';
import { LengthType } from 'common/types/additional';
import { ARGProjectFields } from '@arg,@demo,@greenline/containers/project-fields';
import { toFixedByLengthType } from '@arg,@demo,@greenline/views/AssemblyLengthView/utils';
import AccessoriesRadioButton from '../AccessoriesRadioButton';


const AccessoriesRadioButtonContainer = ({
  option,
}: WithChildren<ICustomOptionProps>) => {
  const lengthType = useLengthTypeData();
  const { getFieldsByName } = useGenericFieldsActionsAdopted();


  const alField = getFieldsByName([ARGProjectFields.assemblyLength])[ARGProjectFields.assemblyLength];
  const lengthInInches = getSingleNumberValue(alField?.value?.selected)!;
  const lengthInFeet = toFixedByLengthType(lengthInInches, LengthType.inches, LengthType.feet);
  const neededLenght = lengthType === LengthType.feet ? lengthInFeet : lengthInInches;

  const lengthWithType = `${neededLenght || 0} ${lengthType}`;
  return (
    <AccessoriesRadioButton
      option={option}
      length={lengthWithType}
    />
  );
};

export default AccessoriesRadioButtonContainer;
