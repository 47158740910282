import { useMutation } from '@tanstack/react-query';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ReactComponent as MailIconSvg } from 'assets/icons/mail.svg';
import { ReactComponent as PrintIconSvg } from 'assets/icons/print.svg';

import { printPDF } from 'api/requestFuncs';
import { useSessionId } from 'containers/GenericFieldsHandlingLayer/context';
import { Summary } from './useSummary';
import styles from './styles.module.scss';
import { DocumentTemplate } from './printer';
import { TranslationFn, useTranslateFn } from '../../../utils/useTranslateFn';
import { TranslationKey } from '../../../@arg,@demo/utils/text-translations';
import { useSetPrinterActions } from '../../../containers/PrinterDependencyInversion/context';

export const emailLink = (pdfUrl: string, email: string, t: TranslationFn<TranslationKey>) => {
  const subject = t('variables.contactEmailTitle');
  const body = `${pdfUrl}`;

  return `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
};
export const PrintSection = ({ summary }: { summary: Summary }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [pdfError, setPdfError] = useState<string>('');
  const sessionId = useSessionId();
  const t = useTranslateFn<TranslationKey>();
  const email = t('variables.contactEmail');

  const {
    mutate,
    isLoading: isPDFLoading,
  } = useMutation(['generate-pdf', summary.generatedConfigId, summary.quantity], async () => {
    const html = DocumentTemplate(summary, summary.quantity, t);

    const url = await printPDF(sessionId!, html);

    return url;
  });

  const onEmail = useCallback(async () => {
    setPdfUrl('');
    setPdfError('');
    const win = window.open('', t('texts.pdf.generate'));
    if (win) {
      win.document.body.innerHTML = t('texts.pdf.generating');
    }
    try {
      await mutate(undefined, {
        onSuccess: (url) => {
          if (win) {
            win.location = emailLink(url, email, t);
          } else {
            window.open(emailLink(url, email, t), '_blank');
          }
          setPdfUrl(url);
        },
        onError: (e) => {
          setPdfError(String(e));
          win?.close();
        },
      });
    } catch (e) {
      setPdfError(String(e));
      win?.close();
    }
  }, [summary.generatedConfigId, summary.quantity]);

  const onDownload = useCallback(async () => {
    setPdfUrl('');
    setPdfError('');
    const win = window.open('', t('texts.pdf.generate'));
    if (win) {
      win.document.body.innerHTML = t('texts.pdf.generating');
    }
    try {
      await mutate(undefined, {
        onSuccess: (url) => {
          if (win) {
            win.location = url;
          } else {
            window.open(url, '_blank');
          }
          setPdfUrl(url);
        },
        onError: (e) => {
          setPdfError(String(e));
          win?.close();
        },
      });
    } catch (e) {
      setPdfError(String(e));
      win?.close();
    }
  }, [summary.generatedConfigId, summary.quantity]);

  useEffect(() => {
    setPdfUrl('');
    setPdfError('');
  }, [summary.generatedConfigId, summary.quantity]);

  useSetPrinterActions(onEmail, onDownload);

  const mailTo = useMemo(() => emailLink(pdfUrl, email, t), [pdfUrl, email, t]);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.title = summary.generatedConfigId;
      iframeRef.current.srcdoc = DocumentTemplate(summary, summary.quantity, t);
    }
  }, [summary.generatedConfigId, summary.quantity, t]);

  return (
    <>
      <iframe title="Test" ref={iframeRef} style={{ width: '100vw', height: '100vh', display: 'none' }} />
      <div className={styles.contact}>
        {/* eslint-disable-next-line */}
        <label>{t('texts.print.contactUs')}</label>
        {/* eslint-disable-next-line */}
        <div>{t('texts.print.agreement')}</div>
        <div className={styles.buttons}>
          {!!pdfUrl && (
            <a href={mailTo} target="_blank" rel="noreferrer">
              <MailIconSvg className={styles.mailIcon} />
              {' '}
              {t('texts.print.sendEmail')}
            </a>
          )}
          {!pdfUrl && (
            <a
              onClick={onEmail}
              href={mailTo}
              target="_blank"
              rel="noreferrer"
            >
              <MailIconSvg className={styles.mailIcon} />
              {' '}
              {t('texts.print.sendEmail')}
            </a>
          )}
          {!!pdfUrl && (
            <a href={pdfUrl} target="_blank" rel="noreferrer">
              <PrintIconSvg className={styles.printIcon} />
              {' '}
              {t('texts.print.generateBtn')}
            </a>
          )}
          {!pdfUrl && (
            <>
              { /* eslint-disable-next-line */ }
              <a onClick={onDownload} target="_blank" rel="noreferrer">
                <PrintIconSvg className={styles.printIcon} />
                {' '}
                {t('texts.print.generateBtn')}
              </a>
            </>
          )}
        </div>
        {!!pdfError && (
          <div>
            <br />
            {t('texts.print.warning')}
          </div>
        )}
        {isPDFLoading && (
          <div>
            <br />
            {t('texts.pdf.generating')}
          </div>
        )}
      </div>
    </>
  );
};
