import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'styles/common.scss';
import { GenericFieldsHandlingLayer } from 'containers/GenericFieldsHandlingLayer';
import { AdditionalDataStorage } from 'containers/AdditionalDataStorage';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonBundle from 'i18n/default.bundle';
import { Helmet } from 'react-helmet';
import { useCssVariables } from './utils/css-variables';
import { configReceiver } from './utils/config-receiver';
import { setRuleSetUuid, setServerAddress } from './common/constants';
import { PrinterDependencyInversion } from './containers/PrinterDependencyInversion';

export const buildApp = async (
  FormSwitcher: React.ComponentType,
  projectSpecificBundle: any,
) => {
  const p = i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: {
        en: { translation: commonBundle },
      },
      partialBundledLanguages: true,
      debug: true,
      lng: 'en', // if you're using a language detector, do not define the lng option
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });


  p.then(() => {
    console.log('i18n initialized');
    i18n.addResourceBundle('en', 'translation', projectSpecificBundle, true, true);
  }).catch((error) => {
    console.error('An error occurred while initializing i18n', error);
  });

  const StyleProvider = ({ children }: { children: React.ReactNode }) => {
    // Note: useMemo fails for some reason
    const template = useCssVariables();

    return (
      <>
        <Helmet>
          <style id="i18n-styles" type="text/css">{template}</style>
        </Helmet>
        {children}
      </>
    );
  };

  const initApp = async () => {
    const config = await configReceiver();
    if (config) {
      console.log('Config received', config);

      if (config.cpqApiUrl) {
        setServerAddress(config.cpqApiUrl);
      }
      if (config.rulesetUuid) {
        setRuleSetUuid(config.rulesetUuid);
      }
    }
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    const queryClient = new QueryClient();

    root.render(<React.StrictMode>
      <PrinterDependencyInversion>
        <StyleProvider>
          <QueryClientProvider client={queryClient}>
            <GenericFieldsHandlingLayer>
              <AdditionalDataStorage>
                <FormSwitcher />
              </AdditionalDataStorage>
            </GenericFieldsHandlingLayer>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </StyleProvider>
      </PrinterDependencyInversion>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.StrictMode>);
  };

  return initApp()
    .catch((error) => console.error('An error occurred while Application was starting', error)); // eslint-disable-line no-console
};


