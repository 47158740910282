import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import Cookies from 'js-cookie';

import type { WithChildren } from 'common/types';
import { ISelectedFieldData, UnitedFieldState } from 'common/types/fields';

import { useTranslation } from 'react-i18next';
import type { ISchemaDataStorage, ISchemaDataStorageOrder, ISchemaDataStorageFields } from './types';
import { FieldsDataHandlerContext, PredefinedContextType } from './context';

import { useFieldsUpdateHandler, useWholeSchemaRequest } from './api';
import { formatSchemaDataToStore, mergeUpdatedFieldsWithCurrentState } from './utils';
import useSearchQuery from '../../utils/useSearchQuery';
import recoverSessionId from '../../utils/recoverSessionId';


export const GenericFieldsHandlingLayer = ({ children }: WithChildren) => {
  const { i18n } = useTranslation();
  const searchParams = useSearchQuery();
  const [order, setOrder] = useState<ISchemaDataStorageOrder<string>>([]);
  const [fieldsDataSchema, setFieldsDataSchema] = useState<ISchemaDataStorageFields<string>>({});
  const [session, setSession] = useState<PredefinedContextType['session']>({ sessionId: recoverSessionId() });
  const [updatedFields, setUpdatedFields] = useState<Array<ISelectedFieldData>>();
  const [isInitializing, setIsInitializing] = useState<boolean>(true);

  const updateFields = useCallback((formUpdatedFields: Array<ISelectedFieldData>) => {
    setUpdatedFields(formUpdatedFields);
    console.log('Update Initialized', formUpdatedFields); // eslint-disable-line no-console
  }, []);

  const handleFieldsUpdate = useCallback((serverUpdatedFields: Array<UnitedFieldState>) => {
    const updatedState = mergeUpdatedFieldsWithCurrentState(fieldsDataSchema, serverUpdatedFields);
    setFieldsDataSchema(updatedState);
    console.log('Update finished', updatedState); // eslint-disable-line no-console
  }, [setFieldsDataSchema, fieldsDataSchema]);

  const getFieldsByName = useCallback((fieldsNames: Array<string>) => (
    fieldsNames.reduce((acc, fieldKey) => ({
      ...acc,
      [fieldKey]: fieldsDataSchema[fieldKey],
    }), {} as ISchemaDataStorage<string>['fields'])
  ), [fieldsDataSchema]);

  const {
    isFetching: isSchemaFetching,
    isLoading: isSchemaLoading,
    parsedError: schemaParsedError,
    data: initialSchemaWithData,
  } = useWholeSchemaRequest(session?.sessionId, searchParams);

  const {
    isFetching: isStateFetching,
    isLoading: isStateLoading,
    parsedError: stateParsedError,
  } = useFieldsUpdateHandler(updatedFields, session?.sessionId, handleFieldsUpdate);

  useEffect(() => {
    if (initialSchemaWithData && !isSchemaFetching && !isSchemaLoading) {
      const parsedState = formatSchemaDataToStore(initialSchemaWithData.state, initialSchemaWithData.schema);
      setOrder(parsedState.order);
      setFieldsDataSchema(parsedState.fields);
      setIsInitializing(false);

      if (initialSchemaWithData.i18n) {
        console.log('I18n', initialSchemaWithData.i18n);
        i18n.addResourceBundle('en', 'translation', initialSchemaWithData.i18n, true, true);
        i18n.changeLanguage('en'); // This forces top level CSS update
      }
    }
    if (initialSchemaWithData?.session.sessionId) {
      setSession({
        sessionId: initialSchemaWithData?.session.sessionId,
      });
      Cookies.set(
        'sessionId',
        initialSchemaWithData?.session.sessionId,
        {
          sameSite: 'lax',
        },
      );
    }
  }, [isSchemaFetching, isSchemaLoading, schemaParsedError, initialSchemaWithData]);

  const contextValue = useMemo<PredefinedContextType>(() => ({
    data: {
      order,
      fields: fieldsDataSchema,
      error: schemaParsedError || stateParsedError,
      isLoading: isSchemaLoading || isSchemaFetching || isStateLoading || isStateFetching || isInitializing,
    },
    session,
    actions: {
      updateFieldsState: updateFields,
      getFieldsByName,
    },
  }), [
    order,
    fieldsDataSchema,
    schemaParsedError,
    stateParsedError,
    isSchemaLoading,
    isSchemaFetching,
    isStateLoading,
    isStateFetching,
    session,
    updateFields,
    getFieldsByName,
  ]);

  return (
    <FieldsDataHandlerContext.Provider value={contextValue}>
      {children}
    </FieldsDataHandlerContext.Provider>
  );
};
