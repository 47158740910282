import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { useGenericFieldsData } from 'containers/GenericFieldsHandlingLayer/context';

import SecondaryNavWrapper from '@arg,@demo,@greenline/containers/components/SecondaryNavWrapper';
import Stepper from 'common/components/Stepper';
import ErrorMessage from 'common/components/ErrorMessage';

import { scrollToEmptyInput } from 'utils/scrollToInput';
import useSearchQuery from 'utils/useSearchQuery';
import { IFormValues } from 'common/components/FormHF/types';
import { ARGProjectFields } from '@arg,@demo,@greenline/containers/project-fields';
import { useTranslateFn } from 'utils/useTranslateFn';
import { TranslationKey } from '@arg,@demo/utils/text-translations';
import { useCommitActionHandler } from 'utils/use-commit-action-handler';
import { mapProjectFieldsToProjectARGCart } from '@arg,@demo,@greenline/api/cart/iframe';
import styles from './styles.module.scss';
import { IStepView } from './types';
import { DEFAULT_ERROR_NO_MESSAGE_JUST_STOP, DEFAULT_ERROR_NO_APPROPRIATE_CONFIGURATION, VIEWS_LIST } from './constants';


const FormSwitcher = () => {
  const searchParams = useSearchQuery();
  const preselectedHoseQuery = searchParams.hose;

  const { fields, isLoading } = useGenericFieldsData<IFormValues<ARGProjectFields>>();
  const [viewStep, setViewStep] = useState<number>(preselectedHoseQuery ? 1 : 0);
  const [stepIndexes, setStepIndexes] = useState<Array<number>>(VIEWS_LIST.map((el, index) => index));
  const [error, setError] = useState<string | undefined>();
  const [isCartLoading, setIsCartLoading] = useState<boolean>(false);
  const navigationEl = useRef<HTMLDivElement>(null);
  const [navigationTop, setNavigationTop] = useState<number>(0);


  const preselectedHoseProduct = fields.hoseProduct?.value?.selected;
  const [hasProcessedPreselection, setHasProcessedPreselection] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading && !hasProcessedPreselection) {
      setHasProcessedPreselection(true);
      if (preselectedHoseQuery) {
        if (preselectedHoseProduct) {
          setViewStep(1);
        } else {
          setViewStep(0);
        }
      }
    }
  }, [isLoading, preselectedHoseQuery, preselectedHoseProduct, hasProcessedPreselection]);

  const scrollToInput = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  useEffect(() => {
    const topOfApp = document.getElementById('root');
    clearTimeout(scrollToInput.current);
    scrollToInput.current = setTimeout(() => {
      topOfApp!.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 300);
  }, [viewStep]);

  useEffect(() => {
    clearTimeout(scrollToInput.current);
    scrollToInput.current = setTimeout(() => {
      scrollToEmptyInput();
    }, 300);
  }, [fields]);

  useEffect(() => {
    navigationEl.current && setNavigationTop(navigationEl.current.getBoundingClientRect().top);
  }, []);

  const handleScrollToMakeSticky = useCallback(() => {
    const scrollTop = window.scrollY;
    if (scrollTop > navigationTop) {
      navigationEl.current?.classList.add(styles.sticky);
    } else {
      navigationEl.current?.classList.remove(styles.sticky);
    }
  }, [navigationTop]);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollToMakeSticky);
    return () => {
      window.removeEventListener('scroll', handleScrollToMakeSticky);
    };
  }, [handleScrollToMakeSticky]);

  const stepsSimplified = useMemo<Array<string>>(
    () => stepIndexes.map((viewIndex) => VIEWS_LIST[viewIndex].stepName),
    [stepIndexes],
  );

  const isLastStep = viewStep + 1 === VIEWS_LIST.length;

  const isNextEnabled = useMemo<boolean>(
    () => (isLastStep || !!stepIndexes.find((index) => viewStep < index)) && !error,
    [isLastStep, stepIndexes, error, viewStep],
  );

  const commitActionHandler = useCommitActionHandler(setError, setIsCartLoading, mapProjectFieldsToProjectARGCart);

  const t = useTranslateFn<TranslationKey>();

  const onNextClickHandler = useCallback(
    () => {
      if (viewStep + 1 < VIEWS_LIST.length) {
        let nextAvailableStep: IStepView | undefined;
        let index = viewStep + 1;
        const visibleViewsIndexes = stepIndexes.filter((viewIndex) => viewIndex < index);
        while (index < VIEWS_LIST.length) {
          const reqField = VIEWS_LIST[index].requiredFields;
          const isAvailableFieldExist = !!reqField.find((fieldName) => (
            !!fields[fieldName] && fields[fieldName]?.status !== 'disabled'
          ));
          if (isAvailableFieldExist || reqField.length === 0) {
            nextAvailableStep = VIEWS_LIST[index];
            visibleViewsIndexes.push(index);
            break;
          }
          index++;
        }
        const availableIndexesAfterCurrent = Array.from(
          { length: VIEWS_LIST.length - index - 1 },
          (el, ind) => ind + index + 1,
        );
        visibleViewsIndexes.push(...availableIndexesAfterCurrent);
        setStepIndexes(visibleViewsIndexes);
        if (!nextAvailableStep) {
          setError(t(DEFAULT_ERROR_NO_APPROPRIATE_CONFIGURATION));
        } else {
          setError(undefined);
          setViewStep(index);
        }
      } else {
        setIsCartLoading(true);
        // Submit to cart
        commitActionHandler();
      }
    },
    [viewStep, stepIndexes, fields, commitActionHandler],
  );

  const onBackClickHandler = useCallback(
    () => {
      const previous = stepIndexes.findIndex((viewIndex) => viewIndex === viewStep);
      if (previous - 1 >= 0) {
        setError(undefined);
        setViewStep(stepIndexes[previous - 1]);
      }
    },
    [viewStep, setViewStep, stepIndexes],
  );

  const onResetHandler = useCallback(
    () => {
      setIsCartLoading(true);
      window.location.hash = 'new';
      window.location.reload();
    },
    [setIsCartLoading],
  );

  const onStepChangeHandler = useCallback(
    (stepName: string) => {
      const step = VIEWS_LIST.findIndex((view) => view.stepName === stepName);
      if (step > viewStep) {
        return false;
      }
      setViewStep(step ?? viewStep);
      return true;
    },
    [viewStep, setViewStep],
  );

  const clearProgressError = useCallback(
    () => {
      if (error) {
        setError(undefined);
      }
    },
    [setError, error],
  );

  useEffect(() => {
    // Dropdowns are only updated normally from server, so need to re-evaluate "next" button
    console.log('error is reset on server updating fields');
    clearProgressError();
  }, [fields]);

  const handleSetNoFieldsAvailable = useCallback(
    () => setError(t(DEFAULT_ERROR_NO_MESSAGE_JUST_STOP)),
    [setError],
  );

  const ViewComponent = VIEWS_LIST[viewStep].component;

  return (
    <div className={styles.wrapper}>
      <div
        ref={navigationEl}
        className={styles.navigation}
      >
        <Stepper
          onClickStepper={onStepChangeHandler}
          steps={stepsSimplified}
          step={VIEWS_LIST[viewStep].stepName}
        />
        <div className={styles.container}>
          <SecondaryNavWrapper />
        </div>
      </div>

      <div className={styles.container}>
        <ViewComponent
          title={t(VIEWS_LIST[viewStep].title)}
          description={t(VIEWS_LIST[viewStep].description)}
          key={VIEWS_LIST[viewStep].title}
          onNextClickHandler={onNextClickHandler}
          onBackClickHandler={onBackClickHandler}
          onStepChange={onStepChangeHandler}
          onResetHandler={onResetHandler}
          fieldsNames={VIEWS_LIST[viewStep].requiredFields}
          isNextDisabled={!isNextEnabled}
          clearProgressError={clearProgressError}
          isLoading={isCartLoading}
          handleSetNoFieldsAvailable={handleSetNoFieldsAvailable}
        >
          {error && (
            <ErrorMessage
              text={error}
              className={styles.commonError}
            />
          )}
        </ViewComponent>
      </div>
    </div>
  );
};

export default FormSwitcher;
