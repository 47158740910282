import { useGenericFieldsData } from 'containers/GenericFieldsHandlingLayer/context';
import { useData } from 'containers/AdditionalDataStorage/context';
import { getSingleNumberValue, getSingleOption, getSingleStringValue } from 'api/cart/utils';
import { mapProjectFieldsToProjectARGCart } from '@arg,@demo,@greenline/api/cart/iframe';
import { toFixedByLengthType } from '@arg,@demo,@greenline/views/AssemblyLengthView/utils';
import { LengthType } from 'common/types/additional';
import { IFormValues } from 'common/components/FormHF/types';
import { ARGProjectFields } from '@arg,@demo,@greenline/containers/project-fields';

export const useSummary = () => {
  const { fields: currentFields } = useGenericFieldsData<IFormValues<ARGProjectFields>>();
  const additionalData = useData();

  const hose = getSingleOption(currentFields.hoseProduct?.value?.selected);
  const fitting1 = getSingleOption(currentFields.fitting1Product?.value?.selected);
  const fitting2 = getSingleOption(currentFields.fitting2Product?.value?.selected);
  const angle = getSingleNumberValue(currentFields.angleOfRotation?.value?.selected);
  const testing = getSingleOption(currentFields.testingConfiguration?.value?.selected);
  const accessories = getSingleOption(currentFields.accessories?.value?.selected);
  const { generatedConfigId } = mapProjectFieldsToProjectARGCart(currentFields, additionalData);
  const quantity = additionalData.configQuantity;


  const lengthInInches = getSingleNumberValue(currentFields.assemblyLength?.value?.selected)!;
  const lengthInFeet = toFixedByLengthType(lengthInInches, LengthType.inches, LengthType.feet);

  const { lengthType } = additionalData;
  const customerPartNumber = getSingleStringValue(currentFields.customerSerialNumber?.value?.selected)!;

  return {
    hose,
    fitting1,
    fitting2,
    accessories,
    angle,
    customerPartNumber,
    lengthInFeet,
    testing,
    lengthInInches,
    lengthType,
    generatedConfigId,
    quantity,
  };
};
export type Summary = ReturnType<typeof useSummary>;
