import { CommitAction } from './commit-action';

export type ShouldWaitForEvent = ()=>boolean;

export type AppConfig = {
    /**
     * Overrides the rule set UUID for the CPQ configuration.
     * TODO: not implemented yet
     */
    rulesetUuid: string;

    /**
     * Overrides the default URL for the CPQ API.
     * TODO: not implemented yet
     */
    cpqApiUrl: string;

    /**
     * Overrides any additional params for CPQ requests.
     * */
    cpqRequestParams?: unknown;

    /**
     * Overrides the request params for executing the commit action. Typically should have API url and headers specified.
     */
    commitRequestParams?: unknown;

    commitAction: CommitAction;
}

export const defaultShouldWaitForEvent = () => window.self !== window.parent && window.location.search.includes('waitForCustomConfig');

export const CONFIG_EVENT_TYPE = '@cpqconfig';

export interface ConfigMessageEvent extends MessageEvent {
    data: {
        type: typeof CONFIG_EVENT_TYPE;
        config: AppConfig;
    }
}

export const isConfigEvent = (event: MessageEvent): event is ConfigMessageEvent => event.data.type === CONFIG_EVENT_TYPE;
export const configReceiver = async (shouldWaitForEvent: ShouldWaitForEvent = defaultShouldWaitForEvent): Promise<AppConfig | undefined> => {
  if (!shouldWaitForEvent()) {
    return undefined;
  }


  const result = new Promise<AppConfig>((resolve) => {
    window.addEventListener('message', (event) => {
      if (isConfigEvent(event)) {
        resolve(event.data.config);
      }
    });
  });

  window.parent.postMessage({ type: CONFIG_EVENT_TYPE }, '*');

  return result;
};
