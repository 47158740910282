import React from 'react';
import { PrinterDependencyInversionContext } from './context';

export const PrinterDependencyInversion = ({ children }: React.PropsWithChildren<{}>) => {
  const [emailExecutor, setEmailExecutor] = React.useState<() => Promise<void>>();
  const [printExecutor, setPrintExecutor] = React.useState<() => Promise<void>>();

  const contextValue = React.useMemo(() => ({
    email: emailExecutor,
    print: printExecutor,
    setEmailExecutor: (executor?: () => Promise<void>) => setEmailExecutor(() => executor),
    setPrintExecutor: (executor?: () => Promise<void>) => setPrintExecutor(() => executor),
  }), [emailExecutor, printExecutor, setEmailExecutor, setPrintExecutor]);

  return (
    <PrinterDependencyInversionContext.Provider value={contextValue}>
      {children}
    </PrinterDependencyInversionContext.Provider>
  );
};
