import HoseSelectorView from '@arg,@demo,@greenline/views/HoseSelectorView';
import AssemblyLengthView from '@arg,@demo,@greenline/views/AssemblyLengthView';
import FittingsSelectionView from '@arg,@demo,@greenline/views/FittingsSelectionView';
import AngleRotationView from '@arg,@demo,@greenline/views/AngleRotationView';
import AccessoriesSelectorView from '@arg,@demo,@greenline/views/AccessoriesSelectorView';

import SummaryView from '@arg/views/SummaryView';
import { ARGProjectFields } from '@arg,@demo,@greenline/containers/project-fields';
import { TranslationKey } from '@arg,@demo/utils/text-translations';
import type { IStepView } from './types';
/**
 * This is a project specific import. When switching projects, look for every import that is marked with @PROJECT
 * This one is already in the specific project folder so needs no changing, so not marked with @PROJECT
 */
import {
  STEP_ACCESSORIES, STEP_ANGLE, STEP_ASS_LENGTH, STEP_CONFIRM, STEP_FITTING, STEP_HOSE,
} from './stepNames';

export const VIEWS_LIST: Array<IStepView<TranslationKey>> = [{
  title: 'texts.step1.title',
  stepName: STEP_HOSE,
  component: HoseSelectorView,
  description: 'texts.step1.description',
  requiredFields: [
    ARGProjectFields.hoseApplicationAttribute,
    ARGProjectFields.hoseMaterialConstruction,
    ARGProjectFields.hosePressure,
    ARGProjectFields.hoseInsideDiameter,
    ARGProjectFields.hoseProduct,
  ],
}, {
  title: 'texts.step2.title',
  stepName: STEP_FITTING,
  component: FittingsSelectionView,
  description: 'texts.step2.description',
  requiredFields: [
    ARGProjectFields.fitting1Gender,
    ARGProjectFields.fitting1ConnectionType,
    ARGProjectFields.fitting1ConnectionSize,
    ARGProjectFields.fitting1Product,
    ARGProjectFields.fitting2Gender,
    ARGProjectFields.fitting2ConnectionType,
    ARGProjectFields.fitting2ConnectionSize,
    ARGProjectFields.fitting2Product,
    ARGProjectFields.fitting2SameAs1,
  ],
}, {
  title: 'texts.step3.title',
  stepName: STEP_ASS_LENGTH,
  component: AssemblyLengthView,
  description: 'texts.step3.description',
  requiredFields: [ARGProjectFields.assemblyLength],
}, {
  title: 'texts.step4.title',
  stepName: STEP_ANGLE,
  component: AngleRotationView,
  description: 'texts.step4.description',
  requiredFields: [ARGProjectFields.angleOfRotation],
}, {
  title: 'texts.step5.title',
  stepName: STEP_ACCESSORIES,
  component: AccessoriesSelectorView,
  description: 'texts.step5.description',
  requiredFields: [ARGProjectFields.accessories, ARGProjectFields.testingConfiguration, ARGProjectFields.customerSerialNumber],
}, {
  title: 'texts.step6.title',
  stepName: STEP_CONFIRM,
  component: SummaryView,
  // eslint-disable-next-line
  description: 'texts.step6.description',
  requiredFields: [],
}];

export const DEFAULT_ERROR_NO_APPROPRIATE_CONFIGURATION: TranslationKey = 'texts.error.noAppropriateConfiguration';
export const DEFAULT_ERROR_NO_MESSAGE_JUST_STOP = 'texts.error.noErrorMessage';
