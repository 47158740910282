import { DeepKeys } from '../utils/type-tools';

const customization = {
  /**
   * Exposed project specific variables like logos, email addresses, etc.
   */
  variables: {
    pdf: {
      logo: '/@arg-pdf-logo.png',
    },
    contactEmail: process.env.REACT_APP_CONTACT_EMAIL,
    contactEmailTitle: 'Custom Assembly Configuration',
  },
  /**
   * CSS variable overrides
   * @see /src/styles/css-variables.scss
   */
  css: {
  },
  texts: {
    pdf: {
      generate: 'Generate PDF',
      generating: 'Generating PDF...',
    },
    print: {
      contactUs: 'Contact Us',
      // eslint-disable-next-line max-len
      agreement: 'Questions or concerns about your configured assembly? Please contact us via email or via our live chat where assembly experts are available to help. You can also generate PDF file of your assembly to attach to your email. We&apos;re happy to assist you.',
      sendEmail: 'Send Email',
      generateBtn: 'Generate PDF with Assembly',
      warning: 'Warning: Could not generate PDF, try again later',
    },
    btn: {
      next: 'Next',
      prev: 'Back',
      reset: 'Reset Values',
      complete: 'Complete My Assembly',
      showMore: 'Show details',
      showLess: 'Hide details',
    },
    error: {
      noErrorMessage: '',
      noAppropriateConfiguration: 'There are no results that match your criteria. Please select other values.',
    },
  },
} as const;

export type Type = typeof customization;
export type Texts = DeepKeys<typeof customization>;

export default customization;
