import React, {
  ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import classNames from 'classnames';


import { ReactComponent as PlusIconSvg } from 'assets/increase.svg';
import { ReactComponent as MinusIconSvg } from 'assets/decrease.svg';
import styles from './styles.module.scss';

export interface IControlledQuantityProps {
  count: number,
  handleSetCounter: (count: number) => void,
  className?: string,
}

const Quantity = ({
  className,
  handleSetCounter,
  count,
}: IControlledQuantityProps) => {
  const onIncreaseHandler = useCallback(() => {
    handleSetCounter(count + 1);
  }, [count, handleSetCounter]);

  const onDecreaseHandler = useCallback(() => {
    handleSetCounter(count - 1);
  }, [count, handleSetCounter]);

  const [strVal, setStrVal] = useState<string>(String(count));

  useEffect(() => {
    setStrVal(String(count));
  }, [count]);

  const onChangeInputHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setStrVal(event.currentTarget.value);
    const value = Number(event.currentTarget.value);

    if (!Number.isNaN(value) && event.currentTarget.value !== '') {
      handleSetCounter(value);
    }
  }, [handleSetCounter, setStrVal]);

  const onBlurHandler = useCallback(() => {
    setStrVal(String(count));
  }, [handleSetCounter, count]);

  return (
    <div className={classNames(styles.quantity, className)}>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        type="button"
        className={classNames(styles.button, styles.subtract, {
          [styles.disabled]: count < 2,
        })}
        onClick={onDecreaseHandler}
      >
        <MinusIconSvg />
      </button>
      <input
        className={styles.input}
        onChange={onChangeInputHandler}
        onBlur={onBlurHandler}
        value={strVal}
      />
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        type="button"
        className={classNames(styles.button, styles.add)}
        onClick={onIncreaseHandler}
      >
        <PlusIconSvg />
      </button>
    </div>
  );
};

export default Quantity;
