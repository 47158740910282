import React, { useMemo } from 'react';

import { SelectedConfiguration } from 'common/types/additional';
import { CardStatus } from 'common/components/CardNav/constants';
import { useActiveSelectedConfiguration } from 'containers/AdditionalDataStorage/context';
import { useGenericFieldsActionsAdopted } from 'containers/FormDataAdapter';

import SecondaryNav from '@arg,@demo,@greenline/containers/components/SecondaryNav';

import emptyImageHose from '@arg,@demo,@greenline/assets/empty-image-hose.svg';
import emptyImageFitting1 from '@arg,@demo,@greenline/assets/empty-image-fitting1.svg';
import emptyImageFitting2 from '@arg,@demo,@greenline/assets/empty-image-fitting2.svg';
import { OptionType } from 'common/components/Select/types';
import { ARGProjectFields } from '@arg,@demo,@greenline/containers/project-fields';

const SecondaryNavWrapper = () => {
  const { getFieldsByName } = useGenericFieldsActionsAdopted();
  const fields = useMemo(
    () => getFieldsByName([
      ARGProjectFields.hoseProduct,
      ARGProjectFields.fitting1Product,
      ARGProjectFields.fitting2Product,
    ]),
    [getFieldsByName],
  );
  const activeSelectedConfiguration = useActiveSelectedConfiguration();

  const hoseSecondaryNav = useMemo(() => {
    const hoseSelected = fields[ARGProjectFields.hoseProduct]?.value?.selected as OptionType[] | undefined;

    return {
      active: activeSelectedConfiguration === SelectedConfiguration.hose,
      status: hoseSelected ? CardStatus.fulfilled : CardStatus.empty,
      defaultImage: emptyImageHose,
      emptyBodyLabel: !hoseSelected ? 'Hose' : undefined,
      selectedItem: hoseSelected ? {
        image: hoseSelected[0].attributes?.image!,
        title: hoseSelected[0].title!,
      } : undefined,
    };
  }, [activeSelectedConfiguration, fields[ARGProjectFields.hoseProduct]?.value?.selected]);

  const fitting1SecondaryNav = useMemo(() => {
    const fitting1Selected = fields[ARGProjectFields.fitting1Product]?.value?.selected as OptionType[] | undefined;

    return {
      active: activeSelectedConfiguration === SelectedConfiguration.fitting1,
      status: fitting1Selected ? CardStatus.fulfilled : CardStatus.empty,
      defaultImage: emptyImageFitting1,
      emptyBodyLabel: !fitting1Selected ? 'Fitting 1' : undefined,
      selectedItem: fitting1Selected ? {
        image: fitting1Selected[0].attributes?.image!,
        title: fitting1Selected[0].title!,
      } : undefined,
    };
  }, [activeSelectedConfiguration, fields.fitting1Product]);

  const fitting2SecondaryNav = useMemo(() => {
    const fitting2Selected = fields[ARGProjectFields.fitting2Product]?.value?.selected as OptionType[] | undefined;

    return {
      active: activeSelectedConfiguration === SelectedConfiguration.fitting2,
      status: fitting2Selected ? CardStatus.fulfilled : CardStatus.empty,
      defaultImage: emptyImageFitting2,
      emptyBodyLabel: !fitting2Selected ? 'Fitting 2' : undefined,
      selectedItem: fitting2Selected ? {
        image: fitting2Selected[0].attributes?.image!,
        title: fitting2Selected[0].title!,
      } : undefined,
    };
  }, [activeSelectedConfiguration, fields.fitting2Product]);

  return (
    <SecondaryNav
      hose={fitting1SecondaryNav}
      fitting2={fitting2SecondaryNav}
      fitting1={hoseSecondaryNav}
    />
  );
};

export default SecondaryNavWrapper;
