import React, {
  SyntheticEvent, useCallback, useEffect, useMemo,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import type { IViewProps } from 'common/types/views';
import type { WithChildren } from 'common/types';

import { FieldsType } from 'common/types/fields';
import { useGenericFieldsActionsAdopted, useGenericFieldsDataAdopted } from 'containers/FormDataAdapter';
import { determineAndApplyErrorToForm } from 'utils/determineAndApplyErrorToForm';
import { SelectedConfiguration } from 'common/types/additional';
import { useActions } from 'containers/AdditionalDataStorage/context';

import FormWrapper from 'common/components/FormWrapper';
import ViewsButtonsBlock from 'common/components/ViewsButtonsBlock';
import { IFormValues } from 'common/components/FormHF/types';
import { prepareDefaultFormData } from 'common/components/FormHF/utils';
import FieldGenerator from 'common/components/FieldGenerator';
import { FormLoader } from 'common/components/Loader';

import { useIsNextStepAvailable } from 'hooks/useIsNextStepAvailable';
import { ICustomDescriptionProps } from 'common/components/FieldGenerator/types';
import { ARGProjectFields } from '@arg,@demo,@greenline/containers/project-fields';
import styles from './styles.module.scss';
import { isFieldDisabled } from '../../../utils/isStatusDisabled';
import FittingDescription from './FittingDescription';

const FittingsSelectionView = ({
  onNextClickHandler,
  onBackClickHandler,
  onResetHandler,
  title,
  description,
  fieldsNames,
  children,
  clearProgressError,
  isNextDisabled,
  handleSetNoFieldsAvailable,
}: WithChildren<IViewProps<ARGProjectFields>>) => {
  const idForm = 'form-fittings-selection';

  const { error, isLoading, order } = useGenericFieldsDataAdopted<IFormValues<ARGProjectFields>>();
  const { updateFieldsState, getFieldsByName } = useGenericFieldsActionsAdopted<IFormValues<ARGProjectFields>>();
  const { setData } = useActions();
  const fields = useMemo(() => getFieldsByName(fieldsNames), [getFieldsByName, fieldsNames]);

  const defaultValues = prepareDefaultFormData(fields);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    formState: { errors },
    resetField,
    setError,
  } = useForm<IFormValues<ARGProjectFields>>({ defaultValues });

  const requiredFieldsForView = useMemo(() => order && order.filter((fieldName) => fieldName !== ARGProjectFields.fitting2SameAs1
          && fieldsNames.includes(fieldName)), [order, fieldsNames]);

  // eslint-disable-next-line no-unused-vars
  const onSubmitSuccess: SubmitHandler<IFormValues<ARGProjectFields>> = useCallback((data) => {
    onNextClickHandler();
  }, [onNextClickHandler]);

  const handleFieldUpdate = useCallback((
    fieldType: FieldsType,
    fieldName: ARGProjectFields,
    changedValue?: IFormValues<ARGProjectFields>[keyof IFormValues<ARGProjectFields>],
  ) => {
    if (clearProgressError) { clearProgressError(); }
    updateFieldsState(fieldType, fieldName, changedValue);
  }, [updateFieldsState, clearProgressError]);

  const onColumnHandler = useCallback((event: SyntheticEvent<HTMLDivElement>) => {
    const columnTitle = event.currentTarget.dataset.column;
    if (columnTitle) {
      setData('activeSelectedConfiguration', columnTitle);
    }
  }, [setData]);

  const renderDescriptionComponent = useCallback<(
    fieldId: ARGProjectFields) => React.FC<ICustomDescriptionProps> | undefined>(
    (fieldId: ARGProjectFields) => {
      switch (fieldId) {
        case ARGProjectFields.fitting1Product:
        case ARGProjectFields.fitting2Product: {
          return FittingDescription;
        }
        default:
          return undefined;
      }
    },
    [],
    );

  useEffect(() => () => setData('activeSelectedConfiguration', null), [setData]);

  useEffect(() => {
    if (error) {
      determineAndApplyErrorToForm(error, setError);
    }
  }, [error, setError]);

  useIsNextStepAvailable(order, fieldsNames, fields, handleSetNoFieldsAvailable);

  const fitting2SameAs2Field = useMemo(() => (
    order && !!order.find((fieldName) => fieldName === ARGProjectFields.fitting2SameAs1) && fields[ARGProjectFields.fitting2SameAs1] && (
      <div className={styles.middle}>
        <FieldGenerator<IFormValues<ARGProjectFields>>
          className={styles.checkbox}
          field={fields[ARGProjectFields.fitting2SameAs1]!}
          name={fields[ARGProjectFields.fitting2SameAs1]!.name as ARGProjectFields}
          control={control}
          watch={watch}
          setValue={setValue}
          getFieldState={getFieldState}
          key={fields[ARGProjectFields.fitting2SameAs1]!.name}
          onChangeHandler={handleFieldUpdate}
          isDisabled={isFieldDisabled(fields[ARGProjectFields.fitting2SameAs1])}
          renderDescriptionComponent={renderDescriptionComponent}
          resetField={resetField}
          isIgnoredForScrolling
        />
      </div>
    )
  ), [control, fields, getFieldState, handleFieldUpdate, order, resetField, setValue, watch]);

  return (
    <FormWrapper
      header={title}
      description={description}
    >
      <form
        id={idForm}
        onSubmit={handleSubmit(onSubmitSuccess)}
      >
        {isLoading && <FormLoader />}

        {requiredFieldsForView.length > 0 && (
          <div className={styles.columns}>
            <div
              className={styles.column}
              tabIndex={0}
              role="button"
              onClick={onColumnHandler}
              onKeyDown={onColumnHandler}
              data-column={SelectedConfiguration.fitting1}
            >
              <div className={styles.label}>Fitting 1</div>
              {requiredFieldsForView.map((fieldName, index) => {
                if (index < Math.floor(requiredFieldsForView.length / 2)) {
                  return (
                    <FieldGenerator
                      className={styles.field}
                      field={fields[fieldName]!}
                      name={fields[fieldName]?.name as ARGProjectFields}
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      getFieldState={getFieldState}
                      key={fields[fieldName]?.name}
                      onChangeHandler={handleFieldUpdate}
                      isDisabled={isFieldDisabled(fields[fieldName])}
                      resetField={resetField}
                      renderDescriptionComponent={renderDescriptionComponent}
                    />
                  );
                }
                return null;
              })}
            </div>
            {fitting2SameAs2Field}
            <div
              className={styles.column}
              tabIndex={0}
              role="button"
              onClick={onColumnHandler}
              onKeyDown={onColumnHandler}
              data-column={SelectedConfiguration.fitting2}
            >
              <div className={styles.label}>Fitting 2</div>
              {requiredFieldsForView.map((fieldName, index) => {
                if (index >= Math.floor(requiredFieldsForView.length / 2)) {
                  return (
                    <FieldGenerator
                      className={styles.field}
                      field={fields[fieldName]!}
                      name={fields[fieldName]?.name as ARGProjectFields}
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      getFieldState={getFieldState}
                      key={fields[fieldName]?.name}
                      onChangeHandler={handleFieldUpdate}
                      isDisabled={isFieldDisabled(fields[fieldName])}
                      renderDescriptionComponent={renderDescriptionComponent}
                      resetField={resetField}
                    />
                  );
                }
                return null;
              })}
            </div>
          </div>
        )}
        {children}
      </form>
      <ViewsButtonsBlock
        idForm={idForm}
        onResetClickHandler={onResetHandler}
        onBackClickHandler={onBackClickHandler}
        isNextDisabled={isNextDisabled}
      />
    </FormWrapper>
  );
};

// @ts-ignore
export default FittingsSelectionView;
