import { useCallback, useRef, useEffect } from 'react';
import { useGenericFieldsData } from 'containers/GenericFieldsHandlingLayer/context';
import { useAdditionalDataStorage } from 'containers/AdditionalDataStorage/context';
import { sendDataToParentIframe } from './send-data-to-parent-iframe';
import { getCommitAction } from '../common/constants';
import { CommitAction } from './commit-action';
import { usePrinterActions } from '../containers/PrinterDependencyInversion/context';
import { ExtendedDataStorageType } from '../containers/AdditionalDataStorage/types';

type VoidCallableType = (() => void) | undefined;

export const useCommitActionHandler = <T>(
  setError: ((errorMessage: string | undefined) => void),
  setIsLoading: ((isLoading: boolean) => void),
  mapper: (data: T, additional: ExtendedDataStorageType) => unknown,
) => {
  const handlerRef = useRef<VoidCallableType>(undefined);
  const { fields } = useGenericFieldsData();
  const { data: additionalData } = useAdditionalDataStorage();
  const printerActions = usePrinterActions();


  useEffect(
    () => {
      handlerRef.current = () => {
        const action = getCommitAction();

        switch (action) {
          case CommitAction.SEND_TO_PARENT_IFRAME:
            sendDataToParentIframe(fields as T, additionalData, mapper).then(({ redirectUrl, message }) => {
              // eslint-disable-next-line no-alert
              alert(message || 'Successfully sent');
              if (redirectUrl) {
                window.location.href = redirectUrl;
              }
            }).catch((er) => {
              setError(String(er));
            }).finally(() => {
              setIsLoading(false);
            });
            break;
          case CommitAction.PRINT:
            if (printerActions.print) {
              printerActions.print().finally(() => {
                setIsLoading(false);
              });
            } else {
              setIsLoading(false);
              setError('Something went wrong. Print action is not available');
            }
            break;
          case CommitAction.SEND_EMAIL:
            if (printerActions.email) {
              printerActions.email().finally(() => {
                setIsLoading(false);
              });
            } else {
              setIsLoading(false);
              setError('Something went wrong. Email action is not available');
            }
            break;
          case CommitAction.DISABLED:
            console.log('Commit action is disabled');
            setIsLoading(false);
            setError('Commit action is disabled');
            break;
          default:
            setError(`No appropriate configuration for commit action ${action}`);
        }
      };

      return () => {
        handlerRef.current = undefined;
      };
    },
    [additionalData, fields, setError, setIsLoading, printerActions.email, printerActions.print, mapper],
  );

  return useCallback(() => {
    if (handlerRef.current) {
      handlerRef.current();
    }
  }, []);
};
