import { useEffect, useMemo } from 'react';

import type { PredefinedContextType } from 'containers/GenericFieldsHandlingLayer/context';
import type { IFormValues } from 'common/components/FormHF/types';
import { FieldsType } from 'common/types/fields';

import { prepareDefaultFormData } from 'common/components/FormHF/utils';
import { DefaultToFormAdaptersFieldType } from 'containers/FormDataAdapter/types';
import { ConfiguredRecordType } from 'containers/GenericFieldsHandlingLayer/types';
import { isGeneratorDropdownFieldType } from '@arg,@demo,@greenline/utils/isGeneratorFieldType';
import { DEFAULT_ANGLE_OPTION } from '@arg,@demo,@greenline/views/AngleRotationView/constants';
import { ARGProjectFields } from '@arg,@demo,@greenline/containers/project-fields';


type S = PredefinedContextType<ARGProjectFields>['data']['fields'];
// this hook could be useless unless we have useEffect hook in FieldGenerator
// which has applied selected props from field property to form
export const usePrepareDefaultAngleOfRotationFormData = (fields: S): IFormValues<ARGProjectFields> => useMemo(() => {
  const defaultValues = prepareDefaultFormData(fields);
  const angleOfRotationField = fields[ARGProjectFields.angleOfRotation];
  if (angleOfRotationField && isGeneratorDropdownFieldType(angleOfRotationField)) {
    let angleVal = angleOfRotationField?.value?.selected ? angleOfRotationField.value.selected[0] : undefined;
    if (!angleVal) {
      angleVal = angleOfRotationField?.value?.options?.length ? angleOfRotationField.value.options[0] : undefined;
    }

    return {
      ...defaultValues,
      ...(angleVal ? { [ARGProjectFields.angleOfRotation]: angleVal } : {}),
    };
  }
  console.error('Angle of rotation field has unexpected field type and can be used properly');
  return defaultValues as IFormValues<ARGProjectFields>;
}, []); // TODO check this later

export const useAngleOfRotationFieldWithDefaultSelected = <TField = DefaultToFormAdaptersFieldType>(
  fields: ConfiguredRecordType<ARGProjectFields, TField>,
  updateFieldsState: (
      fieldType: FieldsType,
      fieldName: ARGProjectFields,
      fieldVal?: IFormValues<ARGProjectFields>[keyof IFormValues<ARGProjectFields>]
  ) => void,
) => {
  useEffect(() => {
    const aorField = fields[ARGProjectFields.angleOfRotation];
    if (aorField && isGeneratorDropdownFieldType(aorField)) {
      if (!aorField.value?.selected) {
        const selected = aorField.value?.options ? aorField.value.options[0] : DEFAULT_ANGLE_OPTION;
        updateFieldsState(FieldsType.dropdown, ARGProjectFields.angleOfRotation, selected);
      }
    }
  }, [fields, updateFieldsState]);

  return useMemo(
    () => {
      const aorField = fields[ARGProjectFields.angleOfRotation];
      if (aorField && isGeneratorDropdownFieldType(aorField)) {
        const adoptedAOR = {
          ...aorField,
        };
        if (!aorField.value?.selected) {
        adoptedAOR.value!.selected = [aorField.value?.options ? aorField.value.options[0] : DEFAULT_ANGLE_OPTION];
        }
        return adoptedAOR;
      }
      return undefined;
    },
    [fields],
  );
};
