import { getApiPrefix, getRuleSetUuid } from '../common/constants';

export const apiEndpoints = () => ({
  getWholeSchema: (sessionId?: string, params?: Record<string, string>) => {
    const allParams = {
      sessionId,
      ruleSetUuid: getRuleSetUuid(),
      ...params,
    };

    // eslint-disable-next-line no-unused-vars
    const suffix = Object.entries(allParams).filter(([k, v]) => !!v).map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v!)}`).join('&');

    return `${getApiPrefix()}/rules-engine/schema${suffix ? `?${suffix}` : ''}`;
  },
  // eslint-disable-next-line no-unused-vars
  updateFieldsState: (sessionId: string) => `${getApiPrefix()}/rules-engine/apply`,
  printPDF: () => `${getApiPrefix()}/summary/generate`,
});
