import { ExtendedDataStorageType } from '../containers/AdditionalDataStorage/types';
import { isCPQIframeData, wrapData } from '../api/cart/utils';
import { ICPQCommitResponse } from '../api/cart/types';

export const sendDataToParentIframe = async <T>(data: T, additional: ExtendedDataStorageType, dataMapper: (data: T, additional: ExtendedDataStorageType) => unknown) => {
  if (window.self !== window.parent) {
    return new Promise<ICPQCommitResponse>((resolve, reject) => {
      window.parent.postMessage(wrapData(dataMapper(data, additional)), '*');

      const listenToResponse = (e: MessageEvent) => {
        if (isCPQIframeData(e.data)) {
          const {
            error, isLoading, message, reset, redirectUrl,
          } = e.data;

          if (!isLoading) {
            if (!error) {
              resolve({ message, reset, redirectUrl });
            } else {
              reject(new Error(error));
            }

            window.removeEventListener('message', listenToResponse);
          }
        }
      };

      window.addEventListener('message', listenToResponse);
    });
  }
  throw new Error('Can only send data to parent iframe being in iframe');
};
