import React, { useEffect } from 'react';

export type PrintPageActions = {
    email?: () => Promise<void>;
    print?: () => Promise<void>;
    setEmailExecutor: (callback?: () => Promise<void>) => void;
    setPrintExecutor: (callback?: () => Promise<void>) => void;
}

export const PrinterDependencyInversionContext = React.createContext<
    PrintPageActions
    | undefined
>(undefined);

export const usePrinterDependencyInversion = () => {
  const context = React.useContext(PrinterDependencyInversionContext);

  if (!context) {
    throw new Error('No PrinterDependencyInversionContext found. This hook have to be used only inside appropriate context provider');
  }
  return context;
};

export const usePrinterActions = () => {
  const context = usePrinterDependencyInversion();

  return {
    email: context.email,
    print: context.print,
  };
};

export const useSetPrinterActions = (emailExecutor: () => Promise<void>, printExecutor: () => Promise<void>) => {
  const context = usePrinterDependencyInversion();

  useEffect(() => {
    context.setEmailExecutor(emailExecutor);
    context.setPrintExecutor(printExecutor);

    console.log('useSetPrinterActions', emailExecutor, printExecutor);

    return () => {
      context.setEmailExecutor();
      context.setPrintExecutor();

      console.log('useSetPrinterActions cleanup');
    };
  }, [context, emailExecutor, printExecutor]);
};
