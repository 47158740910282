import { ConfiguredRecordType } from 'containers/GenericFieldsHandlingLayer/types';
import { GeneratorFieldType } from 'common/components/FieldGenerator/types';
import { ExtendedDataStorageType } from 'containers/AdditionalDataStorage/types';
import { LengthType } from 'common/types/additional';
import { toFixedByLengthType } from '@arg,@demo,@greenline/views/AssemblyLengthView/utils';
import { ARGProjectFields } from '@arg,@demo,@greenline/containers/project-fields';
import {
  addItem, generateConfigId, getSingleNumberValue, getSingleStringValue,
} from 'api/cart/utils';
import { ICPQCart, ICPQItem } from 'api/cart/types';


export const mapProjectFieldsToProjectARGCart = (
  fields: ConfiguredRecordType<ARGProjectFields, GeneratorFieldType>,
  additional: ExtendedDataStorageType,
): ICPQCart => {
  const items: ICPQItem[] = [];
  const requiredComponents: ICPQItem[] = [];
  const accessories: ICPQItem[] = [];

  addItem(items, 'hose', fields.hoseProduct?.value?.selected);
  addItem(items, 'fitting1', fields.fitting1Product?.value?.selected);
  addItem(items, 'fitting2', fields.fitting2Product?.value?.selected);

  const customerPartNumber = getSingleStringValue(fields.customerSerialNumber?.value?.selected)!;

  addItem(requiredComponents, 'required', fields.requiredComponents?.value?.selected);

  addItem(accessories, 'main', fields.accessories?.value?.selected);

  if (fields.testingConfiguration?.value?.selected) {
    addItem(accessories, 'testing', fields.testingConfiguration?.value?.selected);
  }

  const lengthInInches = getSingleNumberValue(fields.assemblyLength?.value?.selected)!;
  const lengthInFeet = toFixedByLengthType(lengthInInches, LengthType.inches, LengthType.feet);

  const assembly = {
    angleOfRotation: getSingleNumberValue(fields.angleOfRotation?.value?.selected)!,
    assemblyLength_feet: lengthInFeet,
    assemblyLength_inches: lengthInInches,
    length_type: additional.lengthType,
  };

  return {
    generatedConfigId: generateConfigId([...items, ...accessories, ...requiredComponents], assembly, customerPartNumber),
    quantity: additional.configQuantity,
    items,
    requiredComponents,
    accessories,
    customerPartNumber,
    assemblyProperties: assembly,
  };
};
