import { useTranslation } from 'react-i18next';

export const useCssVariables = () => {
  const { i18n: instance } = useTranslation();

  // Note: useMemo fails for some reason
  const variableTemplate = (() => {
    const css = instance.getResource('en', 'translation', 'css');
    const vars = Object.keys(css).reduce((acc, key) => {
      acc[`--${key}`] = css[key];
      return acc;
    }, {} as Record<string, string>);

    return `
                :root {
                    ${Object.entries(vars).map(([key, value]) => `${key}: ${value};`).join('\n')}
                }
            `;
  })();

  return variableTemplate;
};
