import React, { useState } from 'react';

import { ICustomDescriptionProps } from 'common/components/FieldGenerator/types';
import { FieldsType } from 'common/types/fields';

import styles from './styles.module.scss';
import { useTranslateFn } from '../../../../../utils/useTranslateFn';

const HoseProductDescription = ({
  field,
}: ICustomDescriptionProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const toggle = () => setOpen((o) => !o);
  const t = useTranslateFn<string>();
  if (field.type === FieldsType.dropdown) {
    const selection = field.value?.selected?.[0];
    const description = selection?.attributes?.description;
    if (description) {
      return (
        <div className={styles.content}>
          {!open ? (
            <button type="button" onClick={toggle}>
              {t('texts.btn.showHoseDetails')}
            </button>
          ) : (
            <button type="button" onClick={toggle}>
              {t('texts.btn.hideFittingDetails')}
            </button>
          )}
          {open && <div>{description}</div>}
        </div>
      );
    }
  }
  return null;
};
export default HoseProductDescription;
