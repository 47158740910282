import React from 'react';

import { ICard } from 'common/components/CardNav/types';

import CardNav from 'common/components/CardNav';

import styles from './styles.module.scss';

interface ISecondaryNavProps {
  hose: ICard,
  fitting2: ICard,
  fitting1: ICard,
}

const SecondaryNav = ({
  hose,
  fitting2,
  fitting1,
}: ISecondaryNavProps) => (
  <div className={styles.secondaryNav}>
    <CardNav
      active={hose.active}
      status={hose.status}
      selectedItem={hose.selectedItem}
      emptyBodyLabel={hose.emptyBodyLabel}
      defaultImage={hose.defaultImage}
    />
    <CardNav
      active={fitting1.active}
      status={fitting1.status}
      selectedItem={fitting1.selectedItem}
      emptyBodyLabel={fitting1.emptyBodyLabel}
      defaultImage={fitting1.defaultImage}
    />
    <CardNav
      active={fitting2.active}
      status={fitting2.status}
      selectedItem={fitting2.selectedItem}
      emptyBodyLabel={fitting2.emptyBodyLabel}
      defaultImage={fitting2.defaultImage}
    />
  </div>
);

export default SecondaryNav;
