import { CommitAction } from '../../utils/commit-action';

let serverAddress = process.env.REACT_APP_API_ADDRESS;
let apiPrefix = process.env.REACT_APP_API_PREFIX;
let ruleSetUuid = process.env.REACT_APP_RULE_SET_UUID;
let commitAction = process.env.REACT_APP_COMMIT_ACTION || CommitAction.SEND_TO_PARENT_IFRAME;

export const getServerAddress = () => serverAddress;

export const setServerAddress = (address: string) => {
  serverAddress = address;
};

export const getApiPrefix = () => apiPrefix;

export const setApiPrefix = (prefix: string) => {
  apiPrefix = prefix;
};

export const getRuleSetUuid = () => ruleSetUuid;

export const setRuleSetUuid = (uuid: string) => {
  ruleSetUuid = uuid;
};

export const getCommitAction = () => commitAction;

export const setCommitAction = (action: CommitAction) => {
  commitAction = action;
};
