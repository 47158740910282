import React from 'react';
import { default as RsTooltip } from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import classNames from 'classnames';

import { ReactComponent as QuestionIconSvg } from 'assets/icons/question-mark.svg';
import { ActionType, Placement, tooltipOffset } from './constants';

import styles from './styles.module.scss';

interface ITooltipProps {
  className?: string,
  content: React.ReactNode,
  placement?: Placement,
  trigger?: ActionType | ActionType[],
  children?: React.ReactElement,
}

const Tooltip = ({
  content,
  className,
  children,
  placement = Placement.topLeft,
  trigger = ActionType.hover,
}: ITooltipProps) => (
  <div className={classNames(styles.tooltip, className)}>
    <RsTooltip
      overlayClassName={styles.content}
      placement={placement}
      align={{ offset: tooltipOffset }}
      trigger={trigger}
      overlay={content}
    >
      {children || (
        <QuestionIconSvg className={styles.icon} />
      )}
    </RsTooltip>
  </div>
);

// @ts-ignore
export default Tooltip;
