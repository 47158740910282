import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import classNames from 'classnames';
import TopFittingSvg from 'assets/top-fitting.svg';
import BottomFittingSvg from 'assets/bottom-fitting.svg';

import { MAX_ROTATION_ANGLE } from './constants';

gsap.registerPlugin(Draggable);

interface IRotationProps {
  rotationSnap: number,
  rotationValue: number,
  onChangeRotationValue?: (angle: number) => void,
  className?: string,
  disabled?: boolean
}

const Rotation = ({
  rotationSnap,
  rotationValue,
  onChangeRotationValue,
  disabled,
  className,
}: IRotationProps) => {
  const bottomFitting = useRef<HTMLDivElement>(null);
  const angleRef = useRef<number>(rotationValue);
  const [angle, setAngle] = useState(rotationValue);

  useEffect(() => {
    setAngle(rotationValue);
    gsap.set(bottomFitting.current, { rotate: rotationValue });
  }, [rotationValue]);

  useEffect(() => {
    if (!disabled) {
      gsap.set(bottomFitting.current, { transition: 'transform .3s ease-out' });

      Draggable.create(bottomFitting.current, {
        type: 'rotation',
        bounds: { maxRotation: MAX_ROTATION_ANGLE, minRotation: 0 },
        liveSnap(endValue) {
          let snappedAngle = Math.round(endValue / rotationSnap) * rotationSnap;
          if (snappedAngle === 360) {
            snappedAngle = 0;
          }
          setAngle(snappedAngle);
          angleRef.current = snappedAngle;
          return snappedAngle;
        },
        onDragEnd() {
          if (onChangeRotationValue) {
            onChangeRotationValue(angleRef.current);
          }
        },
      });
    }
  }, [rotationSnap, onChangeRotationValue, disabled]);

  const strokeDasharray = useMemo(() => 2 * 3.14 * 100, []);

  const strokeDashoffset = useMemo(() => strokeDasharray * ((100 - ((angle / 360) * 100)) / 100), [strokeDasharray, angle]);

  return (
    <div className={classNames('rotation-component', className)}>
      <div className="rotation">
        <span className={classNames('measurement', 'measurement0')}>
          0
          <span>&#176;</span>
        </span>
        <span className={classNames('measurement', 'measurementSm', 'measurement45')}>
          45
          <span>&#176;</span>
        </span>
        <span className={classNames('measurement', 'measurement90')}>
          90
          <span>&#176;</span>
        </span>
        <span className={classNames('measurement', 'measurementSm', 'measurement135')}>
          135
          <span>&#176;</span>
        </span>
        <span className={classNames('measurement', 'measurement180')}>
          180
          <span>&#176;</span>
        </span>
        <span className={classNames('measurement', 'measurementSm', 'measurement225')}>
          225
          <span>&#176;</span>
        </span>
        <span className={classNames('measurement', 'measurement270')}>
          270
          <span>&#176;</span>
        </span>
        <span className={classNames('measurement', 'measurementSm', 'measurement315')}>
          315
          <span>&#176;</span>
        </span>
        <svg className="indicator">
          <circle
            cx={114}
            cy={114}
            r={100}
            className="indicatorTrack"
          />
          <circle
            cx={114}
            cy={114}
            r={100}
            strokeDasharray={strokeDasharray}
            strokeDashoffset={strokeDashoffset}
            className="indicatorProgress"
          />
        </svg>
        <div className="topFitting">
          <img src={TopFittingSvg} alt="Fitting 1" />
        </div>
        <div
          className="bottomFitting"
          ref={bottomFitting}
        >
          <img src={BottomFittingSvg} alt="Fitting 2" />
          <div className="cursor" />
        </div>
      </div>
    </div>
  );
};

export const RotationForPrint = ({
  rotationValue,
  className,
}: { rotationValue: number, className?: string | undefined }) => {
  const strokeDasharray = 2 * 3.14 * 100;

  const strokeDashoffset = strokeDasharray * ((100 - ((rotationValue / 360) * 100)) / 100);

  const fakeClassNames = (...args: string[]) => args.join(' ');

  return `
    <div class="${fakeClassNames('rotation-component', className || '')}">
      <div class="rotation">
        <span class="${fakeClassNames('measurement', 'measurement0')}">
          0
          <span>&#176;</span>
        </span>
        <span class="${fakeClassNames('measurement', 'measurementSm', 'measurement45')}">
          45
          <span>&#176;</span>
        </span>
        <span class="${fakeClassNames('measurement', 'measurement90')}">
          90
          <span>&#176;</span>
        </span>
        <span class="${fakeClassNames('measurement', 'measurementSm', 'measurement135')}">
          135
          <span>&#176;</span>
        </span>
        <span class="${fakeClassNames('measurement', 'measurement180')}">
          180
          <span>&#176;</span>
        </span>
        <span class="${fakeClassNames('measurement', 'measurementSm', 'measurement225')}">
          225
          <span>&#176;</span>
        </span>
        <span class="${fakeClassNames('measurement', 'measurement270')}">
          270
          <span>&#176;</span>
        </span>
        <span class="${fakeClassNames('measurement', 'measurementSm', 'measurement315')}">
          315
          <span>&#176;</span>
        </span>
        <svg class="indicator">
          <circle
            cx="114"
            cy="114"
            r="100"
            class="indicatorTrack"
          />
          <circle
            cx="114"
            cy="114"
            r="100"
            stroke-dasharray="${strokeDasharray}"
            stroke-dashoffset="${strokeDashoffset}"
            class="indicatorProgress"
          />
        </svg>
        <div class="topFitting">
          <img src="https://${window.location.host}${TopFittingSvg}" alt="Fitting 1" />
        </div>
        <div
          class="bottomFitting"
          style="transform: translate(-50%, 0%) rotate(${rotationValue}deg);"
        >
          <img src="https://${window.location.host}${BottomFittingSvg}" alt="Fitting 2" />
          <div class="${'cursor'}"></div>
        </div>
      </div>
    </div>`;
};

export default Rotation;
