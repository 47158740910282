import { GeneratorFieldType } from 'common/components/FieldGenerator/types';
import { ConfiguredRecordType } from 'containers/GenericFieldsHandlingLayer/types';
import { useEffect } from 'react';
import { isFieldDisabled, isFieldRequired } from '../utils/isStatusDisabled';


export const useIsNextStepAvailable = <Key extends string>(
  order: Array<Key>,
  formFieldsNames: Array<Key>,
  fields: ConfiguredRecordType<Key, GeneratorFieldType>,
  setError: (allEmpty: boolean) => void,
) => {
  // mb we need to check if there are no any not disabled fields with empty value and not all fields are filled
  useEffect(() => {
    const orderedFormFields = order.filter((fieldName) => formFieldsNames.includes(fieldName));

    /** Looks for first field that has no value */
    const hasEmptyField = orderedFormFields.some((fieldName) => (
      !!fields[fieldName] // Field exists
      && !isFieldDisabled(fields[fieldName]) // Field is enabled
      && isFieldRequired(fields[fieldName]) // Field is required
      && !fields[fieldName]?.value?.selected // Field has NO value
    ));

    /** Looks for first field that has value */
    const hasNonEmptyField = orderedFormFields.some((fieldName) => (
      !!fields[fieldName] // Field exists
      && !isFieldDisabled(fields[fieldName]) // Field is enabled
      && fields[fieldName]?.value?.selected // Field HAS value
    ));

    /* console.log('useIsNextStepAvailable empty, filled', fields, orderedFormFields.some((fieldName) => (
      !!fields[fieldName] // Field exists
        && !isFieldDisabled(fields[fieldName]) // Field is enabled
        && isFieldRequired(fields[fieldName]) // Field is required
        && !fields[fieldName]?.value?.selected // Field has NO value
    )), orderedFormFields.find((fieldName) => (
      !!fields[fieldName] // Field exists
        && !isFieldDisabled(fields[fieldName]) // Field is enabled
        && fields[fieldName]?.value?.selected // Field HAS value
    ))); */
    if (hasEmptyField) {
      // console.log('Has error')
      setError(!hasNonEmptyField); // If there is non empty required field, then we can't proceed. However inform top layer if any field is already filled
    }
  }, [order, formFieldsNames, fields, setError]);
};

