import React, { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import type { IViewProps } from 'common/types/views';
import type { WithChildren } from 'common/types';

import { FieldsType } from 'common/types/fields';
import { useGenericFieldsActionsAdopted, useGenericFieldsDataAdopted } from 'containers/FormDataAdapter';
import { determineAndApplyErrorToForm } from 'utils/determineAndApplyErrorToForm';

import FormWrapper from 'common/components/FormWrapper';
import ViewsButtonsBlock from 'common/components/ViewsButtonsBlock';
import { IFormValues } from 'common/components/FormHF/types';
import { prepareDefaultFormData } from 'common/components/FormHF/utils';
import FieldGenerator from 'common/components/FieldGenerator';
import { FormLoader } from 'common/components/Loader';
import Tooltip from 'common/components/Tooltip';


import { isFieldDisabled } from 'utils/isStatusDisabled';
import { useIsNextStepAvailable } from 'hooks/useIsNextStepAvailable';
import { ARGProjectFields } from '@arg,@demo,@greenline/containers/project-fields';
import styles from './styles.module.scss';
import AccessoriesRadioButtonContainer from './components/AccessoriesRadioButtonContainer';

const AccessoriesSelectorView = ({
  onNextClickHandler,
  onBackClickHandler,
  onResetHandler,
  title,
  description,
  handleSetNoFieldsAvailable,
  fieldsNames,
  children,
  clearProgressError,
  isNextDisabled,
  isLoading: isCartLoading,
}: WithChildren<IViewProps<ARGProjectFields>>) => {
  const idForm = 'form-accessories';
  const { error, isLoading, order } = useGenericFieldsDataAdopted<IFormValues<ARGProjectFields>>();
  const { updateFieldsState, getFieldsByName } = useGenericFieldsActionsAdopted<IFormValues<ARGProjectFields>>();

  const fields = useMemo(() => getFieldsByName(fieldsNames), [getFieldsByName, fieldsNames]);

  const defaultValues = prepareDefaultFormData(fields);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    formState: { errors },
    resetField,
    setError,
  } = useForm<IFormValues<ARGProjectFields>>({ defaultValues });

  // eslint-disable-next-line no-unused-vars
  const onSubmitSuccess: SubmitHandler<IFormValues<ARGProjectFields>> = useCallback((data) => {
    onNextClickHandler();
  }, [onNextClickHandler]);

  const handleFieldUpdate = useCallback((
    fieldType: FieldsType,
    fieldName: ARGProjectFields,
    changedValue?: IFormValues<ARGProjectFields>[ARGProjectFields],
  ) => {
    if (clearProgressError) {
      clearProgressError();
    }
    updateFieldsState(fieldType, fieldName, changedValue);
  }, [updateFieldsState, clearProgressError]);

  useEffect(() => {
    if (error) {
      determineAndApplyErrorToForm(error, setError);
    }
  }, [error, setError]);

  const renderAccessoriesRadioButton = useCallback(() => AccessoriesRadioButtonContainer, []);

  useIsNextStepAvailable(order, fieldsNames, fields, handleSetNoFieldsAvailable);

  const accessoriesField = useMemo(() => (
    order && !!order.find((fieldName) => fieldName === ARGProjectFields.accessories) && fields[ARGProjectFields.accessories] && (
      <FieldGenerator<IFormValues<ARGProjectFields>>
        className={styles.accessories}
        field={fields[ARGProjectFields.accessories]!}
        name={fields[ARGProjectFields.accessories]!.name as ARGProjectFields}
        control={control}
        watch={watch}
        setValue={setValue}
        getFieldState={getFieldState}
        key={fields[ARGProjectFields.accessories]!.name}
        onChangeHandler={handleFieldUpdate}
        isDisabled={isFieldDisabled(fields[ARGProjectFields.accessories])}
        resetField={resetField}
        renderOptionLabelComponent={renderAccessoriesRadioButton}
      />
    )
  ), [control, fields, getFieldState, handleFieldUpdate, order, renderAccessoriesRadioButton, resetField, setValue, watch]);

  const testingField = useMemo(() => (
    order && !!order.find((fieldName) => fieldName === ARGProjectFields.testingConfiguration) && fields[ARGProjectFields.testingConfiguration] && (
      <div className={styles.testing}>
        <FieldGenerator<IFormValues<ARGProjectFields>>
          className={styles.checkbox}
          field={fields[ARGProjectFields.testingConfiguration]!}
          name={fields[ARGProjectFields.testingConfiguration]!.name as ARGProjectFields}
          control={control}
          watch={watch}
          setValue={setValue}
          getFieldState={getFieldState}
          key={fields[ARGProjectFields.testingConfiguration]!.name}
          onChangeHandler={handleFieldUpdate}
          isDisabled={isFieldDisabled(fields[ARGProjectFields.testingConfiguration])}
          resetField={resetField}
        />
      </div>
    )
  ), [control, fields, getFieldState, handleFieldUpdate, order, resetField, setValue, watch]);

  const customerField = useMemo(() => (
    order && !!order.find((fieldName) => fieldName === ARGProjectFields.customerSerialNumber) && fields[ARGProjectFields.customerSerialNumber] && (
      <div className={styles.partNumber}>
        <div className={styles.partNumberLabel}>
          Customer Part Number
          <Tooltip
            className={styles.iconTooltip}
            content="Optional field to enter a custom part number."
          />
        </div>
        <FieldGenerator<IFormValues<ARGProjectFields>>
          className={styles.input}
          field={fields[ARGProjectFields.customerSerialNumber]!}
          name={fields[ARGProjectFields.customerSerialNumber]!.name as ARGProjectFields}
          control={control}
          watch={watch}
          setValue={setValue}
          getFieldState={getFieldState}
          key={fields[ARGProjectFields.customerSerialNumber]!.name}
          onChangeHandler={handleFieldUpdate}
          isDisabled={isFieldDisabled(fields[ARGProjectFields.customerSerialNumber])}
          resetField={resetField}
        />
      </div>
    )
  ), [control, fields, getFieldState, handleFieldUpdate, order, resetField, setValue, watch]);

  return (
    <FormWrapper
      header={title}
      description={description}
    >
      <form
        id={idForm}
        onSubmit={handleSubmit(onSubmitSuccess)}
      >
        {(isLoading || isCartLoading) && <FormLoader />}
        {accessoriesField}
        <div className={styles.box}>
          {testingField}
          {customerField}
        </div>
        {order && order.map((fieldName) => {
          if (fieldsNames.includes(fieldName as ARGProjectFields) && !!fields[fieldName]) {
            switch (fieldName) {
              case ARGProjectFields.accessories:
              case ARGProjectFields.testingConfiguration:
              case ARGProjectFields.customerSerialNumber:
                return null;
              default: {
                return (
                  <FieldGenerator<IFormValues<ARGProjectFields>>
                    className={styles.input}
                    field={fields[fieldName]!}
                    name={fields[fieldName]!.name as ARGProjectFields}
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    getFieldState={getFieldState}
                    key={fields[fieldName]!.name}
                    onChangeHandler={handleFieldUpdate}
                    isDisabled={isFieldDisabled(fields[fieldName])}
                    resetField={resetField}
                  />
                );
              }
            }
          }
          return null;
        })}
        {children}
      </form>
      <ViewsButtonsBlock
        idForm={idForm}
        onResetClickHandler={onResetHandler}
        onBackClickHandler={onBackClickHandler}
        isNextDisabled={isNextDisabled}
      />
    </FormWrapper>
  );
};

// @ts-ignore
export default AccessoriesSelectorView;
