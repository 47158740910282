import { useMemo } from 'react';

import type { GeneratorInputFieldType } from 'common/components/FieldGenerator/types';
import type { DefaultToFormAdaptersFieldType } from 'containers/FormDataAdapter/types';
import type { ConfiguredRecordType } from 'containers/GenericFieldsHandlingLayer/types';

import { ValidationTypes } from 'common/types/fields';
import { LengthType } from 'common/types/additional';
import { isMaxValidationType, isMinValidationType } from 'utils/isMinMaxValidation';
import { ARGProjectFields } from '@arg,@demo,@greenline/containers/project-fields';
import { ROUNDING_MULTIPLIER } from './constants';


export const toFixedByLengthType = (value: number | string, lengthTypeFrom: LengthType, lengthTypeTo: LengthType) => {
  if (lengthTypeFrom === lengthTypeTo) {
    return Math.round(Number(value) * ROUNDING_MULTIPLIER) / ROUNDING_MULTIPLIER;
  }
  if (lengthTypeTo === LengthType.feet) {
    return Math.round((Number(value) / 12) * ROUNDING_MULTIPLIER) / ROUNDING_MULTIPLIER;
  }
  return Math.round(Number(value) * 12 * ROUNDING_MULTIPLIER) / ROUNDING_MULTIPLIER;
};

export const useTranslateAssemblyLengthToAppropriateLengthType = <TField = DefaultToFormAdaptersFieldType>(
  fields: ConfiguredRecordType<ARGProjectFields, TField>,
  lengthType: LengthType,
) => useMemo(() => {
    if (fields[ARGProjectFields.assemblyLength]) {
      const adoptedALF = {
        ...fields[ARGProjectFields.assemblyLength],

      } as GeneratorInputFieldType; // we need hardcoded type for this field to support custom validation dependent on lengthType
      if (adoptedALF.validation) {
        adoptedALF.validation = adoptedALF
          .validation
          .map((validationObj) => {
            switch (validationObj.type) {
              case ValidationTypes.range: {
                return {
                  ...validationObj,
                  ...(isMinValidationType(validationObj)
                    ? { minValue: toFixedByLengthType(validationObj.minValue, LengthType.inches, lengthType) }
                    : {}),
                  ...(isMaxValidationType(validationObj)
                    ? { maxValue: toFixedByLengthType(validationObj.maxValue, LengthType.inches, lengthType) }
                    : {}),
                };
              }
              default:
                return validationObj;
            }
          });
      }
      if (adoptedALF.value?.selected) {
        const { value } = adoptedALF.value.selected;
        if (value) {
          adoptedALF.value = {
            selected: {
              value: toFixedByLengthType(Number(value), LengthType.inches, lengthType),
            },
          };
        }
      }

      return adoptedALF;
    }
    return undefined;
  }, [fields, lengthType]);
